
import eventBus from '@/utils/event-bus'
import Cookies from 'js-cookie'
import { getLocationForward } from '@/api/urlForward'
import {
  getInfo,
  getValid
} from '@/api/login'
import {sendAction} from "@/plugins/buriedPoint";

export default {
  name : 'HeadNav',
  props : {
  },
  data() {
    return {
      headerIcon : require( '@/assets/images/img_logo.png' ),
      // icon: require('@/assets/images/img_logo.png'),
      icon2 : '',
      idQueryUrl : '',
      activeIndex : '1',
      isValid : false,
      menuList : [
        {
          name : '首页',
          key : '1',
          nav : '/'
        },
        {
          name : '行业案例',
          key : '7',
          nav : '/industryCase'
        },
        {
          name : '产品模板',
          key : '3',
          nav : '/templateLibrary'
        },
        {
          name : '标识入网',
          key : '4',
          nav : '/networkAccess'
        },
        {
          name : '资源中心',
          key : '5',
          nav : process.env.CMS_NEWS_URL
        },
        {
          name : '社区问答',
          key : '6',
          nav : '/community'
        },
        {
          name : '标识生码',
          key : '8',
          nav : '/createCode'
        },
        {
          name : '展示大屏',
          key : '9',
          sub : [
            {
              name : '扫码数据展示大屏',
              key : '90',
              nav : '/scanMap'
            },
            {
              name : '二级节点展示大屏',
              key : '91',
              nav : '/map'
            }
          ]
        },
        {
          name : '标识前缀注册入口',
          key : '10',
          sub : [
            {
              name : '标识前缀注册入口1',
              key : '101',
              nav : '/networkAccess'
            },
            {
              name : '标识前缀注册入口2',
              key : '102',
              nav : 'http://register.asuncloud.com'
            }
          ]
        },
        {
          name : '标识查询',
          key : '11',
          nav : '/identityQuery'
        },
        {
          name : '进销存系统',
          key : '12',
          nav : 'http://zttjxc.asun.cloud/',
          width : '120px'
        },
        {
          name : '智能码大模型',
          key : '13',
          nav : 'https://gpt.zhinengma.cn/chat/322hhhtcHx8ucS1c',
          width : '130px'
        }
      ],
      searVal : ''
    }
  },
  watch : {
    $route( to, from ) {
      console.log( 34, to )
      if ( to.name == 'home' ) {
        this.activeIndex = '1'
      }
      if ( to.name == 'community' || to.name == 'communityAsk' ) {
        this.activeIndex = '6'
      }
      if ( to.name == 'templateLibrary' ) {
        this.activeIndex = '3'
      }
      if ( to.name == 'industryCase' ) {
        this.activeIndex = '7'
      }
      if ( to.name == 'createCode' ) {
        this.activeIndex = '8'
      }
    }
  },
  created() {
    if ( process.browser ) {
      const search = window.location.search
      let url = window.location.protocol + '//' + window.location.host
      if ( search ) {
        url += search
      }
      // let url = 'https://jxm.asun.cloud'
      getLocationForward( url ).then( res => {
        let data
        if ( !res.data ) {
          data = JSON.parse( res.defaultUrl.configParams )
        } else {
          data = JSON.parse( res.data.configParams )
        }
        data.map( i => {
          if ( i.keyName === 'searchUrl' ) {
            this.menuList.push( {
              name : '搜索',
              key : '9',
              nav : i.value && i.value != '' ? i.value : ''
            } )
          } else if ( i.keyName === 'companyIcon' ) {
            this.headerIcon = i.value && i.value != '' ? i.value : ''
          }
        } )
      } )
    }
  },
  mounted() {
    this.activeIndex = Cookies.get( 'activeIndex' )
    eventBus.$on( 'activeIndex', ( k ) => {
      this.activeIndex = k
    } )
    eventBus.$on( 'login', k => {
      this.getValid()
    } )

    this.getValid()
  },
  methods : {
    actionRecord(url,name){
      sendAction({
        operType:'click',
        operDescription:'点击【'+ name +'】网址跳转：' + url,
        page :'智能码-门户首页',
        triggerType :'click'
      })
    },
    handleSelect( key, keyPath ) {
      eventBus.$emit( 'menuBus', key )
      this.activeIndex = key
      this.menuList.map( i => {
        if ( i.key == key ) {
          if ( i.name != '首页' ) {
            document.title = i.name
          } else {
            document.title = '免费二维码制作_在线二维码解决方案_在线二维码制作_免费二维码在线生成_智能码二维码生成器'
          }
        }
      } )
      Cookies.set( 'activeIndex', key )
      this.menuList.map( i => {
        if ( i.key == key ) {
          this.$router.push( {
            path : i.nav
          } )
        }
      } )
    },
    handleSearch() {
      this.$router.push( { path : `/search`, query : { v : this.searVal }} )
    },
    openRegister() {
      eventBus.$emit( 'loginOrRegister', 'openRegister' )
    },
    openToBackLogin() {
      eventBus.$emit( 'loginOrRegister', 'openLogin' )
    },
    goToBack() {
      getInfo().then( res => {
        if ( res ) {
          window.open( `https://${window.location.hostname}/console/dashboard`, '_blank' )
        }
      } )
    },
    getValid() {
      getValid().then( res => {
        this.isValid = res.data
      } )
    },
    async logout() {
      this.$confirm( '确定退出系统吗？', '提示', {
        confirmButtonText : '确定',
        cancelButtonText : '取消',
        type : 'warning'
      } ).then( () => {
        this.$store.dispatch( 'FedLogOut' ).then( () => {
          location.href = '/'
          Cookies.set( 'activeIndex', '1' )
          Cookies.remove( 'nickName' )
        } )
      } ).catch( () => { } )
    }
  }
}
