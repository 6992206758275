
import Cookies from 'js-cookie'
import eventBus from '@/utils/event-bus'
import { feedbackAdd } from '@/api/login'
import { getLocationForward } from '@/api/urlForward'
import { VEmojiPicker } from 'v-emoji-picker'
import { getLinks } from '@/api/policy/index'
import { v4 as UUIDV4 } from 'uuid'
import { getHistory } from '@/api/gpt/gpt'

let source = null
let userId = ''
let chatId = ''
let connected = false
export default {
  name : 'Footers',
  components : {
    VEmojiPicker
  },
  data() {
    return {
      baseUrl : process.env.BASE_URL,
      uploadUrl : process.env.BASE_URL + '/file/upload/image?pageFunc=反馈图片',
      version : process.env.VERSION,
      clientWidth : 1920,
      gzhImg : require( '@/assets/images/gzhImg.png' ),
      xcxImg : require( '@/assets/images/xcxImg.png' ),
      // flImg: require('@/assets/images/indexHome/111.png'),
      smile : require( '@/assets/images/smile.png' ),
      maIcon : require( '@/assets/images/indexHome/maIcon.png' ),
      data : {},
      chartsShow : false,
      chartsShowM : false,
      actIndex : '1',
      servers : [
        // {
        //   icon : require( '@/assets/images/indexHome/fk.gif' ),
        //   name : '反馈',
        //   key : '4'
        // },

        {
          icon : require( '@/assets/images/indexHome/price.png' ),
          name : '报价',
          key : '6'
        },
        {
          icon : require( '@/assets/images/indexHome/kf.png' ),
          name : '客服',
          key : '1'
        },

        {
          icon : require( '@/assets/images/indexHome/sq.png' ),
          name : '社群',
          key : '2'
        },
        {
          icon : require( '@/assets/images/indexHome/fl.png' ),
          name : '福利',
          key : '3'
        },

        {
          icon : require( '@/assets/images/indexHome/top.png' ),
          name : '',
          key : '5'
        }
      ],
      type : 0,
      // sqIcons: [
      //   {
      //     name: '微信公众号',
      //     icon: ''
      //   },
      //   {
      //     name: '微信小程序',
      //     icon: ''
      //   },
      //   {
      //     name: '官方微信群',
      //     icon: ''
      //   }
      // ],
      csqIcons : [
        {
          name : '微信公众号',
          icon : ''
        },
        {
          name : '微信小程序',
          icon : ''
        },
        {
          name : '官方微信群',
          icon : ''
        }
      ],
      ruleForm : {
        images : '',
        phone : '',
        email : '',
        content : ''
      },
      rules : {
        content : [
          { required : true, message : '请输入建议意见', trigger : 'blur' }
        ],
        phone : [
          {
            validator : function( rule, value, callback ) {
              if ( value && value !== '' ) {
                if ( /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/.test( value ) == false ) {
                  callback( new Error( '请输入正确手机号码' ) )
                } else {
                  // 校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger : 'blur'
          }
        ],
        email : [
          {
            validator : function( rule, value, callback ) {
              if ( value && value !== '' ) {
                if ( /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/.test(
                  value ) == false ) {
                  callback( new Error( '请输入正确邮箱地址' ) )
                } else {
                  // 校验通过
                  callback()
                }
              } else {
                callback()
              }
            },
            trigger : 'blur'
          }
        ]
      },
      customerQrCode : '',
      info : null,
      bInfo : {},
      customerName : '',
      spoken : '',
      talkList : [],
      gptTalkList : [],
      loading : false,
      showEmoji : false,
      isBusiness : false,
      links : null
    }
  },

  computed : {},
  watch : {
    // '$route' : {
    //   handler(name) {
    //     if (name === '/company' || name === '/business') {
    //       this.getCustomPageSetting()
    //       this.isBusiness = true
    //     } else {
    //       this.getLocationForward()
    //       this.isBusiness = false
    //     }
    //   },
    //   immediate : true,
    //   deep : true
    // },
  },
  mounted() {
    if ( process.browser ) {
      this.getLocationForward()
    }
    this.clientWidth = document.body.clientWidth
    this.actIndex = Cookies.get( 'activeIndex' )
    eventBus.$on( 'menuBus', ( k ) => {
      this.actIndex = k
    } )
    this.getLinks()
    // if (this.$router.currentRoute.path === '/company' || this.$router.currentRoute.path === '/business') {
    //   this.getCustomPageSetting()
    //   this.isBusiness = true
    // } else {
    //   this.getLocationForward()
    //   this.isBusiness = false
    // }

    const cacheChatId = localStorage.getItem( 'kf-chatId' )
    const cacheUserId = localStorage.getItem( 'kf-userId' )
    if ( cacheChatId && cacheChatId != 'undefined' ) {
      chatId = cacheChatId
    }

    if ( !cacheUserId || cacheUserId == 'undefined' ) {
      userId = UUIDV4()
      localStorage.setItem( 'kf-userId', userId )
    } else {
      userId = cacheUserId
    }

    if ( userId && cacheChatId ) {
      const query = {
        userId : userId,
        conversationId : cacheChatId
      }
      getHistory( query ).then( res => {
        if ( res.code === 200 ) {
          const messageData = res.data
          if ( messageData.data != null && messageData.data.length > 0 ) {
            const messageList = messageData.data
            for ( let i = 0; i < messageList.length; i++ ) {
              const msg = messageList[i]
              const me = {
                type : 'me',
                messageId : 'me-' + msg.messageId,
                msg : msg.query
              }
              this.gptTalkList.push( me )

              const you = {
                type : 'you',
                messageId : 'you-' + msg.messageId,
                msg : msg.answer
              }
              this.gptTalkList.push( you )
            }
          }
        }
      } )
    }
  },
  methods : {
    findGptTalkByMessageIdAndAppendMsg( messageId, msg ) {
      const talk = this.gptTalkList.find( item => item.messageId === messageId )
      if ( talk ) {
        talk.msg = talk.msg + msg
      } else {
        this.gptTalkList.push( {
          type : 'you',
          messageId,
          msg
        } )
      }

      this.$nextTick( () => {
        const msgEnd = document.getElementById( 'msg_end' )
        msgEnd.scrollIntoView( { behavior : 'smooth' } )
      } )
    },
    openSee( msg ) {
      const that = this
      if ( window.EventSource ) {
        // 建立连接
        source = new EventSource(
          this.baseUrl + '/gpt/connect?conversationId=' + chatId + '&userId=' + userId + '&message=' + msg )
        /**
         * 连接一旦建立，就会触发open事件
         * 另一种写法：source.onopen = function (event) {}
         */
        source.addEventListener( 'open', function( e ) {
          console.log( '打开链接' )
          connected = true
        }, false )

        /**
         * 客户端收到服务器发来的数据
         * 另一种写法：source.onmessage = function (event) {}
         */
        source.addEventListener( 'message', function( e ) {
          const data = e.data
          if ( e.data ) {
            const obj = JSON.parse( data )
            if ( !chatId ) {
              chatId = obj.conversation_id
              localStorage.setItem( 'kf-chatId', chatId )
            }
            let answer = obj.answer
            if ( answer ) {
              answer = answer.replace( /\\/g, '%' )
              answer = unescape( answer )
            }

            const messageId = obj.message_id
            if ( messageId && answer ) {
              that.findGptTalkByMessageIdAndAppendMsg( messageId, answer )
            }

            // 消息传输完成，关闭链接
            if ( obj.event == 'message_end' ) {
              that.closeSse()
            }
          }
        } )

        source.addEventListener( 'close', function( event ) {
          // 在这里处理关闭事件
          console.log( '关闭链接' )
          // 可以选择关闭EventSource连接
          source.close()
          connected = false
        } )

        /**
         * 如果发生通信错误（比如连接中断），就会触发error事件
         * 或者：
         * 另一种写法：source.onerror = function (event) {}
         */
        source.addEventListener( 'error', function( e ) {
          connected = false
          that.closeSse()
          if ( e.readyState === EventSource.CLOSED ) {
          } else {
            console.log( e )
          }
        }, false )
      } else {

      }
    },
    closeSse() {
      connected = false
      if(source != null){
        source.close()
      }
      const httpRequest = new XMLHttpRequest()
      httpRequest.open( 'GET', this.baseUrl + '/gpt/disconnection?conversationId=' + chatId, true )
      httpRequest.send()

      document.getElementById( 'spoken' ).focus()
    },
    getLinks() {
      getLinks().then( res => {
        this.links = res.data
      } )
    },
    clickRouter( k ) {
      eventBus.$emit( 'menuBus', k )
    },
    userHand() {
      this.$modal.msgWarning( '该功能暂未开放' )
    },
    show() {
      this.$message( '暂未开放' )
    },
    chartsOpenM() {
      this.chartsShowM = true
      this.type = 0
    },
    chartsOpen() {
      this.chartsShow = true
      this.type = 0
      document.documentElement.style.overflowY = 'hidden'
      this.$nextTick( () => {
        const msgEnd = document.getElementById( 'msg_end' )
        msgEnd.scrollIntoView( { behavior : 'smooth' } )
      } )
    },
    chartsClose() {
      this.chartsShow = false
      this.chartsShowM = false
      document.documentElement.style.overflowY = 'auto'
      this.closeSse()
    },
    serveClick( v ) {
      if ( this.type === v ) {
        this.type = 0
      } else {
        this.type = v
      }
      this.ruleForm = {
        images : '',
        phone : '',
        email : '',
        content : ''
      }
      if ( v === '6' ) {
        this.$router.push( '/price' )
      }
      if ( v === '5' ) {
        if ( process.browser ) {
          document.body.scrollTop = 0
          document.documentElement.scrollTop = 0
          window.scrollTo( {
            left : 0,
            top : 0,
            behavior : 'smooth'
          } )
        }
      }
    },
    beforeAvatarUpload( file ) {
      const types = ['image/jpg', 'image/jpeg', 'image/png', 'image/JPG', 'image/JPEG', 'image/PNG']
      const isImg = types.includes( file.type )

      const isLt2M = file.size / 1024 / 1024 < 5
      if ( !isLt2M ) {
        this.$message( {
          type : 'warning',
          message : '上传图片大小不能超过 5M!'
        } )
        return false
      }
      if ( !isImg ) {
        this.$message( {
          type : 'warning',
          message : '上传图片格式错误，仅限jpg，jpeg，png格式'
        } )
        return false
      }
      return isLt2M && isImg
    },
    handleAvatarSuccess( res ) {
      this.ruleForm.images = res.data
    },
    submitFk() {
      // if (this.ruleForm.images === '' && this.ruleForm.phone === '' && this.ruleForm.email === '' && this.ruleForm.content === '') {
      //   this.$message({
      //     type: "warning",
      //     message: '请填写内容'
      //   })
      //   return
      // }
      this.$refs.ruleForm.validate( ( valid ) => {
        if ( valid ) {
          feedbackAdd( this.ruleForm ).then( ( res ) => {
            if ( res.code === 200 ) {
              this.$message( {
                type : 'success',
                message : '提交成功'
              } )
              this.type = 0
              this.ruleForm = {
                images : '',
                phone : '',
                email : '',
                content : ''
              }
            }
          } )
        } else {
          console.log( 'error submit!!' )
          return false
        }
      } )
    },
    // getCustomPageSetting() {
    //   getCustomPageSetting().then(res => {
    //     const data = JSON.parse(res.msg)
    //     this.bInfo = data
    //     console.log(3344, data)
    //     this.customerName = data.customerServiceStaffName
    //     this.csqIcons[0].icon = data.officialQrCode
    //     this.csqIcons[1].icon = data.minipgQrCode
    //     this.csqIcons[2].icon = data.wechatGroupQrCode
    //   })
    // },
    getLocationForward() {
      const search = window.location.search
      let url = window.location.protocol + '//' + window.location.host
      if ( search ) {
        url += search
      }
      // let url = 'https://jxm.asun.cloud'
      getLocationForward( url ).then( res => {
        let data
        if ( !res.data ) {
          data = JSON.parse( res.defaultUrl.configParams )
        } else {
          data = JSON.parse( res.data.configParams )
        }
        data.map( i => {
          if ( i.keyName === 'userManual' ) {
            this.bInfo.userManual = i.value
          } else if ( i.keyName === 'productUpdate' ) {
            this.bInfo.productUpdate = i.value
          } else if ( i.keyName === 'communityAnswer' ) {
            this.bInfo.communityAnswer = i.value
          } else if ( i.keyName === 'industryCase' ) {
            this.bInfo.industryCase = i.value
          } else if ( i.keyName === 'productTemplate' ) {
            this.bInfo.productTemplate = i.value
          } else if ( i.keyName === 'dataSafe' ) {
            this.bInfo.dataSafe = i.value
          } else if ( i.keyName === 'privacyPolicy' ) {
            this.bInfo.privacyPolicy = i.value || 'https://www.zhinengma.cn/doc/隐私条款.docx'
          } else if ( i.keyName === 'userAggrement' ) {
            this.bInfo.userAggrement = i.value || 'https://www.zhinengma.cn/doc/用户协议.docx'
          } else if ( i.keyName === 'aboutUs' ) {
            this.bInfo.aboutUs = i.value
          } else if ( i.keyName === 'customerQrCode' ) {
            this.customerQrCode = i.value
          } else if ( i.keyName === 'customerName' ) {
            this.customerName = i.value
          } else if ( i.keyName === 'officialQrCode' ) {
            this.gzhImg = i.value
            this.csqIcons[0].icon = i.value
          } else if ( i.keyName === 'minipgQrCode' ) {
            this.xcxImg = i.value
            this.csqIcons[1].icon = i.value
          } else if ( i.keyName === 'wechatGroupQrCode' ) {
            this.csqIcons[2].icon = i.value
          } else if ( i.keyName === 'customerMobile' ) {
            this.bInfo.customerMobile = i.value.split( '\n' )[1]
          } else if ( i.keyName === 'consultTime' ) {
            this.bInfo.consultTime = i.value.split( '\n' )[0]
          }
        } )

        // this.bInfo = {
        //   "userManual":data['userManual']?data['userManual'],
        //   "productUpdate":,
        //   "communityAnswer":,
        //   "industryCase":,
        //   "productTemplate":,
        //   "dataSafe":,
        //   "privacyPolicy":,
        //   "userAggrement":,
        //   "aboutUs":,
        // }
        // this.gzhImg = data.officialQrCode
        // this.xcxImg = data.minipgQrCode
        // this.customerQrCode = data.customerQrCode
        // this.customerName = data.customerServiceStaffName
        // this.sqIcons[0].icon = data.officialQrCode
        // this.sqIcons[1].icon = data.minipgQrCode
        // this.sqIcons[2].icon = data.wechatGroupQrCode
      } )
    },
    showEmojis() {
      this.showEmoji = !this.showEmoji
    },
    selectEmoji( emoji ) {
      const input = document.getElementById( 'spoken' )
      const startPos = input.selectionStart
      const endPos = input.selectionEnd
      const resultText = input.value.substring( 0, startPos ) + emoji.data + input.value.substring( endPos )
      input.value = resultText
      input.focus()
      input.selectionStart = startPos + emoji.data.length
      input.selectionEnd = startPos + emoji.data.length
      this.spoken = resultText
    },
    sendSpoken() {
      this.showEmoji = false
      if ( this.spoken === '' ) {
        this.$message( {
          type : 'warning',
          message : '请输入内容！'
        } )
        return
      }
      if ( connected ) {
        return
      } else {
        connected = true
      }
      this.gptTalkList.push( {
        type : 'me',
        messageId : UUIDV4(),
        msg : this.spoken
      } )
      this.openSee( this.spoken )
      this.spoken = ''
      var hid = document.getElementById( 'msg_end' )
      hid.click()
    },
    createMaIcon() {
      this.$router.push( {
        path : '/maEmote'
      } )
      this.type = 0
    }
    // 添加iframe
    // setNewIframe() {
    //   let iframe = document.createElement('iframe')
    //   iframe.src = "https://im.gongyema.com/webvisitor/tab/main.php?userid=88118096"
    //   iframe.height = "555px"
    //   iframe.width = "608px"
    //   iframe.id = 'chart_iframe'

    //   //onload，iframe加载完成后的回调
    //   if (iframe.attachEvent) {
    //     iframe.attachEvent('onload', () => {
    //       this.setIframeRemove() //移除iframe不需要的元素
    //     })
    //   } else {
    //     iframe.onload = () => {
    //       this.setIframeRemove() //移除iframe不需要的元素
    //     }
    //   }
    //   document.getElementById('chart_div')?.appendChild(iframe) //向指定dom插入iframe
    // },
    // //删除iframe元素
    // setIframeRemove() {
    //   let iframe = document.getElementById('chart_iframe')
    //   debugger
    //   let iframe_title = iframe?.contentDocument.getElementsByClassName('iframe_title')?.[0]
    //   iframe_title && iframe_title.style.display === 'none'
    // }
  }
}
